
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const IS_VERIFYING = 'IS_VERIFYING';
export const PARAMS ="PARAMS";
export const VERIFICATION_LINKPOC_START = "VERIFICATION_LINKPOC_START";
export const VERIFICATION_LINKPOC_SUCCESS = "VERIFICATION_LINKPOC_SUCCESS";
export const VERIFICATION_LINKPOC_FAILURE = "VERIFICATION_LINKPOC_FAILURE";
export const PDFFILE_BASE64_DATA = "PDFFILE_BASE64_DATA";
export const PDFFILE_BASE64_DATA_FAILURE = "PDFFILE_BASE64_DATA_FAILURE";
export const UPLOAD_S3_FILE_SUCCESS = "UPLOAD_S3_FILE_SUCCESS";
export const UPLOAD_S3_FILE_FAILURE = "UPLOAD_S3_FILE_FAILURE";
export const UPLOAD_S3_FILE_START = "UPLOAD_S3_FILE_START";
export const LASTLINK_CLICK_START = "LASTLINK_CLICK_START";
export const LASTLINK_CLICK_FAILURE = "LASTLINK_CLICK_FAILURE";
export const LASTLINK_CLICK_SUCCESS = "LASTLINK_CLICK_SUCCESS";
export const LIVEFEEDBACK_START = "LIVEFEEDBACK_START";
export const LIVEFEEDBACK_SUCCESS = "LIVEFEEDBACK_SUCCESS";
export const LIVEFEEDBACK_FAILURE = "LIVEFEEDBACK_FAILURE";
export const ONESHOTUPLOAD_START = "ONESHOTUPLOAD_START";
export const ONESHOTUPLOAD_SUCCESS = "ONESHOTUPLOAD_SUCCESS";
export const ONESHOTUPLOAD_FAILURE = "ONESHOTUPLOAD_FAILURE";
export const SESSION_UPDATE = "SESSION_UPDATE";
export const SESSION_FAILURE = "SESSION_FAILURE";
export const GET_LAST_CLICKED_LINK = "GET_LAST_CLICKED_LINK";
export const UNSUPPORTED_MEDIA = "UNSUPPORTED_MEDIA";
export const ALL_LIVEFEEDBACK_DATA = "ALL_LIVEFEEDBACK_DATA";
export const FILE_ALREADY_EXISTS = "FILE_ALREADY_EXISTS";
export const DOCUMENT_NOT_ORIGINAL = "DOCUMENT_NOT_ORIGINAL";
export const BUTTONID_IS_NULL = "BUTTONID_IS_NULL";
export const LIVEFEEDBACK_INVALID_DOC = "LIVEFEEDBACK_INVALID_DOC";
export const REDIRECT_TO_UPLOAD = "REDIRECT_TO_UPLOAD";
export const SOMETHING_WENT_WRONG = "SOMETHING_WENT_WRONG";
export const PASSWORD_PROTECTED = "PASSWORD_PROTECTED";
export const LIMIT_EXCEED = "LIMIT_EXCEED";
export const CORRUPT_FILE = "CORRUPT_FILE";
